<template>
  <div class="container" v-loading="loading">
    <div class="content">
      <breadcrumb :items="currentLocation" class="bread-crumb" />
      <div class="search-input" v-if="dataList">
        <div class="top">
          <h3>高级检索</h3>
          <span>*单个条件内的多个选项间是“或”的关系，多个条件之间是“且”的关系。</span>
        </div>
        <el-form class="form" width="80%" ref="form" :model="form" label-width="120px">
          <el-form-item label="自定义条件：">
            <el-col :span="14">
              <el-input
                placeholder="请输入关键词"
                v-model="searchList[0].word"
                clearable
              ></el-input>
            </el-col>
            <el-col :span="5">
              <el-select
                v-model="searchList[0].fieldname"
                placeholder="请选择字段"
                clearable
              >
                <el-option
                  v-for="option in options"
                  :key="option.value"
                  :value="option.value"
                  :label="option.label"
                />
              </el-select>
            </el-col>
            <el-col :span="4">
              <button class="choose-tag add-row" @click.prevent="addRow">添加一行</button>
            </el-col>
          </el-form-item>
          <el-form-item v-for="(row, idx) in rows" :key="row">
            <el-col :span="14">
              <el-input
                placeholder="请输入关键词"
                v-model="searchList[idx + 1].word"
                clearable
              ></el-input>
            </el-col>
            <el-col :span="5">
              <el-select
                v-model="searchList[idx + 1].fieldname"
                placeholder="请选择字段"
                clearable
              >
                <el-option
                  v-for="option in options"
                  :key="option.value"
                  :value="option.value"
                  :label="option.label"
                />
              </el-select>
            </el-col>
            <el-col :span="4">
              <el-select v-model="searchList[idx + 1].logicalop" placeholder="请选择">
                <el-option
                  v-for="item in relation"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                />
              </el-select>
            </el-col>
            <el-col :span="1">
              <i class="el-icon-error" @click.prevent="removeRow(idx)" />
            </el-col>
          </el-form-item>
          <el-form-item label="出版社：">
            <div class="tags">
              <advanced-tag
                v-for="(tag, idx) in tags.publisher"
                :key="tag.label"
                :tag="tag"
                @close-tag="closeTag(idx, 'publisher')"
              />
              <button class="choose-tag" @click.prevent="addTags('publisher')">
                选择出版社
              </button>
            </div>
          </el-form-item>
          <el-form-item label="出版时间：">
            <el-date-picker
              v-model="form.startTime"
              @change="changeTime(0)"
              id="start-time"
              ref="startTime"
              value-format="yyyy-MM-dd"
              placeholder="开始时间"
            />
            <span class="date-divide">至</span>
            <el-date-picker
              v-model="form.endTime"
              @change="changeTime(1)"
              id="end-time"
              ref="endTime"
              value-format="yyyy-MM-dd"
              placeholder="截止时间"
            />
          </el-form-item>

          <el-divider class="form-divider" />

          <el-form-item label="资源类型：">
            <el-checkbox-group v-model="form.dataTypes">
              <!-- <el-checkbox v-for="type in getOptions('纸电')" :key="type.value" :label="type.value" :value="type.value">{{ type.label }}</el-checkbox> -->
              <el-checkbox
                v-for="item in getOptions('纸电')"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="装帧：">
            <el-checkbox-group v-model="form.bindTypes">
              <el-checkbox
                v-for="item in getOptions('装帧')"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="语种：">
            <div class="tags">
              <advanced-tag
                v-for="(tag, idx) in tags.language"
                :key="tag.label"
                :tag="tag"
                @close-tag="closeTag(idx, 'language')"
              />
              <button class="choose-tag" @click.prevent="addTags('language')">
                选择语种
              </button>
            </div>
          </el-form-item>
          <el-form-item label="内容类型：">
            <div class="tags">
              <advanced-tag
                v-for="(tag, idx) in tags.contentType"
                :key="tag.label"
                :tag="tag"
                @close-tag="closeTag(idx, 'contentType')"
              />
              <button class="choose-tag" @click.prevent="addTags('contentType')">
                选择内容类型
              </button>
            </div>
          </el-form-item>
          <el-form-item label="读者群：">
            <el-checkbox-group v-model="form.readers">
              <el-checkbox
                v-for="type in getOptions('读者群')"
                :key="type.value"
                :label="type.value"
                :value="type.value"
              >
                {{ type.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-divider class="form-divider" />

          <el-form-item label="分类法：">
            <el-radio v-model="form.treeType" :label="0" @change="changeTreeType">
              学科分类
            </el-radio>
            <el-radio v-model="form.treeType" :label="1" @change="changeTreeType">
              中图法分类
            </el-radio>
          </el-form-item>
          <el-form-item v-if="!form.treeType" label="学科分类：">
            <div class="tags">
              <advanced-tag
                v-for="(tag, idx) in tags.tree"
                :key="tag.label"
                :tag="tag"
                @close-tag="closeTag(idx, 'tree')"
              />
              <button class="choose-tag" @click.prevent="addTags('subject')">
                选择学科
              </button>
            </div>
          </el-form-item>
          <el-form-item v-else label="中图法分类：">
            <div class="tags">
              <advanced-tag
                v-for="(tag, idx) in tags.tree"
                :key="tag.label"
                :tag="tag"
                @close-tag="closeTag(idx, 'tree')"
              />
              <button class="choose-tag" @click.prevent="addTags('classify')">
                选择中图法
              </button>
            </div>
          </el-form-item>

          <el-divider class="form-divider" />

          <el-form-item label="特殊限定：">
            <el-checkbox-group v-model="form.special">
              <el-checkbox
                v-for="type in getOptions('特殊限定')"
                :key="type.label"
                :label="type.value"
                :value="type.value"
              >
                {{ type.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-divider class="form-divider" />

          <el-form-item>
            <div class="buttons">
              <el-button
                type="primary"
                @click.prevent="doSearch"
                :disabled="disableSearch"
              >
                检索
              </el-button>
              <el-button type="plain" @click.prevent="clearInput">重置</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      v-if="dataList"
      :title="'请选择' + dialogTitle"
      width="800px"
      class="select-dialog"
      @close="cancelTag"
    >
      <div class="dialog-content">
        <span>{{ dialogTitle }}：</span>
        <lazy-select
          v-if="isPublisher"
          :selected="selectedPublishers"
          @change-select="changeCode"
        />
        <el-cascader
          v-else-if="isTree"
          v-model="dialogNodes"
          :key="treeKey"
          :options="dataList[optionStr]"
          :props="{ label: 'name', value: 'ruid', multiple: true, checkStrictly: true }"
          :show-all-levels="false"
          ref="subjectTree"
          class="cascader"
          clearable
        />
        <el-select v-else v-model="dialogTags[dialogModel]" clearable multiple>
          <el-option
            v-for="item in dataList[optionStr]"
            :key="item.value"
            :label="item.label"
            :value="item"
          />
        </el-select>
      </div>
      <div class="dialog-buttons">
        <el-button @click.prevent="cancelTag">取消</el-button>
        <el-button type="primary" @click.prevent="confirmTag">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Breadcrumb from 'components/Breadcrumb'
import LazySelect from '@/components/LazySelect.vue'
import AdvancedTag from './AdvancedTag.vue'
// import _ from 'lodash'

import specialOptions from 'assets/js/specialOptions'

export default {
  name: 'AdvancedSearch',
  components: {
    Breadcrumb,
    LazySelect,
    AdvancedTag,
  },
  data() {
    return {
      currentLocation: [
        {
          name: '资源首页',
          path: '/home',
        },
        {
          name: '高级检索',
          path: '',
        },
      ],
      form: {
        publisherTags: [],
        dataTypes: [],
        bindTypes: [],
        languageTags: [],
        contentTypes: [],
        readers: [],
        subjectTags: [],
        special: [],
        startTime: '',
        endTime: '',
        treeType: 0,
      },
      rows: [],
      num: 1,
      select: '',
      obj: {},
      selects: [
        '纸电',
        '装帧',
        '读者群',
        '分类法',
        '学科限定',
        '语种',
        '内容类型',
        '出版社',
        '出版时间',
      ],
      options: [
        {
          label: '题名',
          value: '10100001,10110001,10120001,125,126,127',
        },
        {
          label: '作者信息',
          value: '15900011,129',
        },
        {
          label: '出版社',
          value: '10400001',
        },
        {
          label: 'ISBN',
          value: '281450001,104,888000009,888000010',
        },
      ],
      searchOption: '',
      relation: [
        {
          label: '与',
          value: 'and',
        },
        {
          label: '或',
          value: 'or',
        },
      ],
      searchList: [
        {
          title: '自定义条件',
          relationop: 'like',
          logicalop: 'and',
        },
      ],
      selectList: {},
      dataList: null,
      subjects: [],
      classify: [],
      codeKey: 0,
      publisherName: '',
      publisher: null,
      publishTime: {},
      disableSearch: false,
      showDialog: false,
      isPublisher: false,
      isTree: false,
      tags: {
        publisher: [],
        language: [],
        contentType: [],
        tree: [],
      },
      dialogTags: {
        publisher: [],
        language: [],
        contentType: [],
        tree: [],
      },
      dialogModel: '',
      optionStr: '',
      selectedNodes: [],
      dialogNodes: [],
      treeKey: 0,
      publishers: [],
      selectedPublishers: [],
      updated: false,
      special: specialOptions,
      loading: false,
    }
  },
  computed: {
    dialogTitle() {
      const type = this.dialogModel
      let title
      switch (type) {
        case 'publisher':
          title = '出版社'
          break
        case 'language':
          title = '语种'
          break
        case 'contentType':
          title = '内容类型'
          break
        case 'subject':
          title = '学科分类'
          break
        case 'classify':
          title = '中图法分类'
          break
      }
      return title
    },
  },
  updated() {
    this.setDateIcon()
  },
  methods: {
    setDateIcon() {
      if (this.updated) return false
      let startTime = this.$refs.startTime.$el
      // let startTime = document.getElementById('start-time')
      let prefix = startTime.getElementsByClassName('el-input__prefix')[0]
      let icon = prefix.getElementsByClassName('el-icon-date')[0]
      let suffix = startTime.getElementsByClassName('el-input__suffix')[0]
      suffix.appendChild(icon)

      let endTime = this.$refs.endTime.$el
      // let endTime = document.getElementById('end-time')
      let prefix2 = endTime.getElementsByClassName('el-input__prefix')[0]
      let icon2 = prefix2.getElementsByClassName('el-icon-date')[0]
      let suffix2 = endTime.getElementsByClassName('el-input__suffix')[0]
      suffix2.appendChild(icon2)
      this.updated = true
    },
    addTags(str) {
      if (str === 'publisher') {
        this.isPublisher = true
      } else if (str === 'subject' || str === 'classify') {
        this.isTree = true
        this.isPublisher = false
      } else {
        this.isTree = false
        this.isPublisher = false
      }

      this.setPublisher()
      this.dialogNodes = this.selectedNodes

      this.dialogModel = str
      this.optionStr = str + 'SelectOptionList'
      this.showDialog = true
    },
    closeTag(idx, type) {
      let list = this.tags[type]
      list.splice(idx, 1)
      this.tags[type] = list
      if (type === 'tree') {
        let nodes = this.selectedNodes
        nodes.splice(idx, 1)
        this.treeKey++
      } else if (type === 'publisher') {
        this.publishers = this.publishers.filter((item) => {
          const targetIdx = list.findIndex((elem) => elem.value === item.customercode)
          if (targetIdx !== -1) return item
        })
        this.selectedPublishers = this.publishers.map((item) => item.customercode)
      }
    },
    setPublisher() {
      this.dialogTags = JSON.parse(JSON.stringify(this.tags))
      this.publishers = this.tags.publisher
      this.selectedPublishers = this.publishers.map((item) => item.value)
    },
    cancelTag() {
      this.setPublisher()
      this.dialogNodes = this.selectedNodes
      this.showDialog = false
    },
    confirmTag() {
      this.tags = JSON.parse(JSON.stringify(this.dialogTags))
      this.selectedNodes = this.dialogNodes
      if (this.dialogModel === 'subject' || this.dialogModel === 'classify') {
        this.changeTree()
      }
      this.showDialog = false
    },
    changeTree() {
      const nodes = this.$refs.subjectTree.getCheckedNodes()
      let tags = nodes.map((node) => {
        return { label: node.label, value: node.value }
      })
      this.tags.tree = tags
    },
    changeTreeType() {
      this.selectedNodes = []
      this.tags.tree = []
      this.treeKey++
    },
    changeCode(objs) {
      this.publishers = objs
      this.dialogTags.publisher = objs.map((item) => {
        return { label: item.name, value: item.customercode }
      })
    },
    doSearch() {
      // 校验自定义条件
      let searchList = this.searchList
      let errorMsg
      searchList.forEach((item, index) => {
        if (index === 0) {
          if (!item.word && item.fieldname) return (errorMsg = '请输入检索词')
          else if (!item.fieldname && item.word) return (errorMsg = '请选择字段')
          return
        }
        if (!item.word) return (errorMsg = '请输入检索词')
        if (!item.fieldname) return (errorMsg = '请选择字段')
        if (!item.logicalop) return (errorMsg = '请选择关系')
      })
      if (errorMsg) {
        return this.$message.error(errorMsg)
      }

      const filters = this.getFilters()
      sessionStorage.setItem('searchFilter', JSON.stringify(filters))
      this.$router.push('BookList')
    },
    getFilters() {
      const wordList = this.searchList.filter((item) => item.word)
      let searchObj = {
        title: '自定义条件',
        name: '',
        fieldname: '',
        relationop: 'group',
        children: [],
      }
      wordList.forEach((el, idx) => {
        searchObj.name += idx ? `,${el.word}` : el.word
        if (idx === 1) {
          searchObj.children[0].logicalop = el.logicalop
        }
        if (el.word.substring(0, 3) === '978') {
          searchObj.children.push({
            word: this.lodash.replace(el.word, /-/g, ''),
            fieldname: el.fieldname,
            logicalop: el.logicalop,
            relationop: el.relationop,
          })
        } else {
          searchObj.children.push({
            word: el.word,
            fieldname: el.fieldname,
            logicalop: el.logicalop,
            relationop: el.relationop,
          })
        }
      })
      const inputFilters = [searchObj]
      const checkboxFilters = this.getCheckboxFilters()
      const tagFilters = this.getTagFilters()
      const dateFilters = this.getDateFilters()
      let leftFilters = checkboxFilters.concat(tagFilters)
      leftFilters = leftFilters.concat(dateFilters)
      const topFilters = this.getTopFilters()

      const searchFilters = {
        search: inputFilters.map((item) => {
          item.advanced = true
          return item
        }),
        left: leftFilters.map((item) => {
          item.advanced = true
          return item
        }),
        top: topFilters.map((item) => {
          item.advanced = true
          return item
        }),
        date: dateFilters.map((item) => {
          item.advanced = true
          return item
        }),
      }
      // const searchFilters = {
      //   search: inputFilters,
      //   left: leftFilters,
      //   top: topFilters,
      //   date: dateFilters
      // }
      return searchFilters
    },
    checkInput() {
      // 校验自定义条件
      let searchList = this.searchList
      let errorMsg
      searchList.forEach((item, index) => {
        if (index === 0) {
          if (!item.word && item.fieldname) return (errorMsg = '请输入检索词')
          else if (item.word && !item.fieldname) return (errorMsg = '请选择字段')
          return
        }
        if (!item.word) return (errorMsg = '请输入检索词')
        if (!item.fieldname) return (errorMsg = '请选择字段')
        if (!item.logicalop) return (errorMsg = '请选择关系')

        let searchObj = {
          relationop: 'group',
          title: '自定义条件',
          name: '',
          children: [],
        }
        searchList.forEach((el) => {
          searchObj.name += el.word
          searchObj.children.push(el)
        })
      })
      if (errorMsg) {
        return this.$message.error(errorMsg)
      }
    },
    getDateFilters() {
      let filters = []
      const start = this.form.startTime
      const end = this.form.endTime
      if (!start || !end) return filters

      filters = [
        {
          logicalop: 'and',
          fieldname: '10500011',
          relationop: '>=',
          valuetype: 'D',
          title: '出版时间',
          word: start,
        },
        {
          logicalop: 'and',
          fieldname: '10500011',
          relationop: '<=',
          valuetype: 'D',
          title: '出版时间',
          word: end,
        },
      ]
      return filters
    },
    getTopFilters() {
      const tree = this.tags.tree
      const isClc = this.form.treeType
      let filter = {
        word: '',
        fieldname: '',
        logicalop: 'and',
        relationop: 'group',
        type: isClc ? 'clc' : 'subject',
        title: isClc ? '中图法分类' : '学科分类',
        children: [],
      }
      tree.forEach((item) => {
        if (filter.name) {
          filter.name += `,${item.label}`
        } else {
          filter.name = item.label
        }
        filter.children.push({
          fieldname: isClc ? 331450001 : 331440001,
          relationop: 'supclass',
          logicalop: 'or',
          word: item.value,
        })
      })
      if (filter.children.length) {
        return [filter]
      } else {
        return []
      }
    },
    getTagFilters() {
      let filters = []
      const tags = this.tags
      for (let key in tags) {
        let tag = tags[key]
        if (!tag || !tag.length) continue
        let obj = {
          logicalop: 'and',
          relationop: 'in',
        }
        switch (key) {
          case 'publisher':
            {
              let filter = JSON.parse(JSON.stringify(obj))
              filter.title = '出版社'
              filter.fieldname = 10400001
              tag.forEach((item) => {
                if (filter.word) {
                  filter.name += `,${item.label}`
                  filter.word += `,${item.label}`
                } else {
                  filter.name = item.label
                  filter.word = item.label
                }
              })
              filters.push(filter)
            }
            break
          case 'language':
            {
              let filter = JSON.parse(JSON.stringify(obj))
              filter.title = '语种'
              filter.fieldname = 112
              tag.forEach((item) => {
                if (filter.word) {
                  filter.name += `,${item.label}`
                  filter.word += `,${item.value}`
                } else {
                  filter.name = item.label
                  filter.word = item.value
                }
              })
              filters.push(filter)
            }
            break
          case 'contentType':
            {
              let filter = JSON.parse(JSON.stringify(obj))
              filter.title = '内容类型'
              filter.fieldname = 110
              tag.forEach((item) => {
                if (filter.word) {
                  filter.name += `,${item.label}`
                  filter.word += `,${item.value}`
                } else {
                  filter.name = item.label
                  filter.word = item.value
                }
              })
              filters.push(filter)
            }
            break
        }
      }
      return filters
    },
    getCheckboxFilters() {
      let filters = []
      let obj = {
        logicalop: 'and',
        relationop: 'in',
        valuetype: 'N',
      }
      const form = this.form
      const list = this.dataList
      let specialFilter = {
        advanced: true,
        title: '特殊限定',
        fieldname: '',
        relationop: 'group',
        name: '',
        children: [],
      }
      for (let key in form) {
        if (!form[key] || !form[key].length) continue
        switch (key) {
          case 'dataTypes':
            {
              let arr = form[key]
              let filter = JSON.parse(JSON.stringify(obj))
              filter.title = '资源类型'
              filter.fieldname = 106
              filter.valuetype = 'N'
              if (arr.length === 3) {
                filter.word = ''
                filter.name = '纸书,电子书,纸+电'
              } else if (arr.length === 2) {
                let checked = [arr[0], arr[1]].sort()
                const sum = checked.reduce((a, b) => a + b)
                if (sum === 1) {
                  filter.word = '1987051001,1987052001'
                  filter.name = '电子书,纸书'
                } else if (sum === 2) {
                  filter = {
                    title: '资源类型',
                    name: '纸书,纸+电',
                    logicalop: 'and',
                    fieldname: '',
                    relationop: 'group',
                    word: '',
                    children: [
                      {
                        logicalop: 'or',
                        fieldname: '106',
                        relationop: '=',
                        word: '1987051001',
                      },
                      {
                        logicalop: 'or',
                        fieldname: '106',
                        relationop: 'group',
                        word: '1987054001',
                      },
                    ],
                  }
                } else {
                  filter = {
                    title: '资源类型',
                    name: '电子书,纸+电',
                    logicalop: 'and',
                    fieldname: '',
                    relationop: 'group',
                    word: '',
                    children: [
                      {
                        logicalop: 'or',
                        fieldname: '106',
                        relationop: '=',
                        word: '1987052001',
                      },
                      {
                        logicalop: 'or',
                        fieldname: '106',
                        relationop: 'group',
                        word: '1987054001',
                      },
                    ],
                  }
                }
              } else if (arr.length === 1) {
                let val = arr[0]
                if (val === 0) {
                  filter = {
                    title: '资源类型',
                    name: '纸书',
                    logicalop: 'and',
                    fieldname: '',
                    relationop: 'group',
                    word: '',
                    children: [
                      {
                        logicalop: 'and',
                        fieldname: '106',
                        relationop: '=',
                        word: '1987051001',
                      },
                    ],
                  }
                } else if (val === 1) {
                  filter = {
                    title: '资源类型',
                    name: '电子书',
                    logicalop: 'and',
                    fieldname: '',
                    relationop: 'group',
                    word: '',
                    children: [
                      {
                        logicalop: 'and',
                        fieldname: '106',
                        relationop: '=',
                        word: '1987052001',
                      },
                    ],
                  }
                } else {
                  filter = {
                    title: '资源类型',
                    name: '纸+电',
                    logicalop: 'and',
                    fieldname: '',
                    relationop: 'group',
                    word: '',
                    children: [
                      {
                        logicalop: 'and',
                        fieldname: '106',
                        relationop: '=',
                        word: '1987054001',
                      },
                    ],
                  }
                }
              }
              filters.push(filter)
            }
            break
          case 'bindTypes':
            {
              let filter = JSON.parse(JSON.stringify(obj))
              filter.title = '装帧'
              filter.fieldname = 107
              form[key].forEach((word) => {
                let name = list.bindingSelectOptionList.find(
                  (item) => item.value === word
                ).label
                if (!filter.word && filter.word != 0) {
                  filter.word = word
                  filter.name = name
                } else {
                  filter.word += `,${word}`
                  filter.name += `,${name}`
                }
              })
              filters.push(filter)
            }
            break
          case 'readers':
            {
              let filter = JSON.parse(JSON.stringify(obj))
              filter.title = '读者群'
              filter.fieldname = 111
              form[key].forEach((word) => {
                let name = list.readerSelectOptionList.find(
                  (item) => item.value === word
                ).label
                if (filter.word === 0 || filter.word) {
                  filter.word += `,${word}`
                  filter.name += `,${name}`
                } else {
                  filter.word = word
                  filter.name = name
                }
              })
              filters.push(filter)
            }
            break
          case 'special':
            form[key].forEach((filter, idx) => {
              specialFilter.name += idx ? `,${filter.name}` : filter.name
              specialFilter.children.push(filter)
              // filters.push(filter)
            })
            break
        }
      }
      if (specialFilter.children.length) {
        filters.push(specialFilter)
      }
      return filters
    },
    changeSelect(val, name) {
      let selects = this.selects
      let selectList = this.selectList
      if (name === '分类法') {
        if (val.value === 1) {
          selects[4] = '中图法限定'
          delete selectList['学科限定']
        } else {
          selects[4] = '学科限定'
          delete selectList['中图法限定']
        }
        this.selects = selects
        this.selectList = selectList
      }
    },
    changeTime(idx) {
      this.disableSearch = true
      let time = {
        start: this.form.startTime,
        end: this.form.endTime,
      }

      if (!time.start && !time.end) {
        this.disableSearch = false
        return
      } else if (idx === 0) {
        if (time.end && time.end < time.start) {
          this.$message.error('开始时间不能晚于结束时间')
          time.start = ''
          if (time.end) {
            this.disableSearch = true
          }
        } else if (time.end && !time.start) {
          this.disableSearch = true
        }
      } else if (idx === 1) {
        if (time.start && time.start > time.end && time.end) {
          this.$message.error('结束时间不能早于开始时间')
          time.end = ''
          if (time.start) {
            this.disableSearch = true
          }
        } else if (time.start && !time.end) {
          this.disableSearch = true
        }
      }

      this.disableSearch = false
      this.form.startTime = time.start
      this.form.endTime = time.end
    },
    getOptions(item) {
      const list = this.dataList
      switch (item) {
        case '纸电':
          return list.typeSelectOptionList
        case '装帧':
          return list.bindingSelectOptionList
        case '读者群':
          return list.readerSelectOptionList
        case '分类法':
          return [
            {
              label: '学科分类',
              value: 0,
            },
            {
              label: '中图法分类',
              value: 1,
            },
          ]
        case '语种':
          return list.languageSelectOptionList
        case '内容类型':
          return list.contentTypeSelectOptionList
        case '特殊限定':
          return this.special
      }
      return []
    },
    getRecentYears() {
      let year = new Date().getFullYear()
      let years = []
      while (year > 2000) {
        years.push(year)
        year--
      }
      return years
    },
    addRow() {
      if (this.num === 4) return this.$message.error('最多只可添加四个自定义条件')

      this.searchList.push({
        title: '自定义条件',
        relationop: 'like',
        logicalop: 'and',
        advanced: true,
      })
      this.rows.push(this.num)
      this.num++
    },
    clearInput() {
      this.$confirm('确定要重置吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.resetForm('form')
          this.rows = []
          this.num = 1
          this.tags = {
            publisher: [],
            language: [],
            contentType: [],
            tree: [],
          }
          this.form = {
            publisherTags: [],
            dataTypes: [],
            bindTypes: [],
            languageTags: [],
            contentTypes: [],
            readers: [],
            subjectTags: [],
            special: [],
            startTime: '',
            endTime: '',
            treeType: 0,
          }
          this.selectedNodes = []
          this.dialogTags = []
          this.dialogNodes = []
          this.publishers = []
          this.selectedPublishers = []
          this.searchList = [{ relationop: 'like', logicalop: 'and', advanced: true }]
        })
        .catch(() => {})
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    removeRow(index) {
      let rows = this.rows
      rows.pop()
      this.searchList.splice(index + 1, 1)
      this.rows = rows
      this.num--
    },
    async initData() {
      this.loading = true
      const res = await this.$http.get('/api/v1/data/newInit')
      let list = res.returnvalue.dataSelect
      list.typeSelectOptionList = [
        {
          label: '纸书',
          value: 0,
        },
        {
          label: '电子书',
          value: 1,
        },
      ]
      this.dataList = list
      this.getSubjects()
      this.loading = false
    },
    async getSubjects() {
      const res = await this.$http.get('/api/v1/data/clcsubject/getAllSubjectTree')
      this.dataList.subjectSelectOptionList = res.returnvalue[0].children
      this.getClassify()
    },
    async getClassify() {
      const res = await this.$http.get('/api/v1/data/clcsubject/getAllClcTree')
      this.dataList.classifySelectOptionList = res.returnvalue[0].children
    },
  },
  mounted() {
    this.initData()
  },
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #eeefef;
  padding-bottom: 30px;
  font-family: Microsoft YaHei;
  /deep/ .el-input__inner {
    padding: 0px 15px;
    font-size: 18px;
    border-radius: 0;
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    .bread-crumb {
      padding: 20px 0;
    }
    .search-input {
      background-color: #fff;
      padding: 30px;
      /deep/ label,
      /deep/ span,
      /deep/ button {
        font-size: 18px;
      }
      .top {
        h3 {
          font-size: 24px;
          display: inline-block;
        }
        span {
          margin-left: 10px;
          color: #ff5555;
          font-size: 14px;
        }
      }
      .form {
        // width: 80%;
        padding: 0 100px;
        margin: 0 auto;
        margin-top: 30px;
        .el-form-item {
          margin-bottom: 15px;
          /deep/ .el-form-item__label {
            color: #333;
          }
        }
        .el-icon-error {
          color: #ddd;
        }
        .tags > * {
          margin-right: 10px;
          margin-bottom: 10px;
        }
        .choose-tag {
          padding: 10px 15px;
          color: #2f8bd6;
          background-color: #eaf3fb;
          border: none;
          height: 40px;
          cursor: pointer;
        }
        .add-row {
          width: 100%;
        }
        .form-divider {
          margin: 30px 0;
        }
        .date-divide {
          margin: 0 16px;
        }
        .buttons > button {
          padding: 10px 30px;
          &:first-child {
            background: linear-gradient(to right, #3891d9, #006fca);
          }
        }
        /deep/ .el-col {
          text-align: center;
        }
        /deep/ .el-col-5 {
          padding-left: 10px;
        }
        /deep/ .el-col-4 {
          padding-left: 10px;
        }
        /deep/ .el-col-1 {
          text-align: end;
          i {
            font-size: 1.6rem;
            color: #f00;
            cursor: pointer;
            line-height: 40px;
          }
        }
        /deep/ .el-select {
          width: 100%;
        }
        .publish-time {
          display: flex;
          span {
            margin: 0 15px;
          }
        }
      }
    }
  }
}
.cascader {
  width: 100%;
}
.select-dialog {
  /deep/ .el-dialog__header {
    padding: 15px 30px;
    background-color: #2f8bd6;
    span,
    i {
      color: #fff;
      font-size: 20px;
    }
  }
  /deep/ .el-dialog__body {
    padding: 30px 60px;
  }
  .dialog-content {
    display: flex;
    align-items: center;
    > span {
      width: 150px;
      font-size: 20px;
      color: #000;
    }
    /deep/ .el-tag--info {
      display: inline-block;
    }
  }
  .dialog-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    button {
      padding: 10px 25px;
    }
    .el-button--default {
      background-color: #f0f0f0;
      border: 1px solid #f0f0f0;
    }
  }
}
</style>
