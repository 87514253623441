<template>
  <div class="bread-box">
    <span>当前位置：</span>
    <el-breadcrumb separator-class="el-icon-arrow-right" class="bread-crumb">
      <el-breadcrumb-item
        v-for="item in items"
        :key="item.name"
        :to="item.path"
        class="breadcrumb-item"
      >{{ item.name }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    items: Array,
  }
}
</script>

<style lang="scss" scoped>
  .bread-box {
    padding: 10px 0;
    display: flex;
    align-items: center;
    > span {
      line-height: 1rem;
      color: #666666;
    }
    .bread-crumb {
      display: inline-block;
      .breadcrumb-item {
        font-size: 1rem;
        &:last-child {
          & /deep/ .el-breadcrumb__inner {
            color: $mainColor;
          }
        }
        & /deep/ .is-link {
          font-weight: normal;
          color: #666;
          &:hover {
            color: $mainColor;
          }
        }
        /deep/ .el-breadcrumb__separator {
          color: #000;
        }
      }
    }
  }
</style>
