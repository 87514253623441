<template>
  <div class="tag">
    <span>{{ tag.label }}</span>
    <i class="el-icon-close" @click="closeTag"/>
  </div>
</template>

<script>
export default {
  name: 'AdvancedTag',
  props: {
    tag: Object
  },
  data() {
    return {
    }
  },
  methods: {
    closeTag() {
      this.$emit('close-tag', this.tag)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tag {
    display: inline-block;
    padding: 0 15px;
    background-color: #F5F5F5;
    color: #333;
    height: 40px;
    font-weight: 400;
    span {
      display: inline-block;
      font-size: 18px;
      padding-right: 10px;
    }
    i {
      font-size: 15px;
      padding: 2px;
      cursor: pointer;
      &:hover {
        color: #fff;
        background-color: #909399;
        border-radius: 50%;
      }
    }
  }
</style>
