<template>
  <el-select
    v-model="value"
    placeholder="请选择或搜索"
    :clearable="clearable"
    :remote-method="searchItem"
    v-el-select-loadmore="loadmore"
    filterable
    remote
    multiple
    :loading="loading"
    @change="changeSelect"
    @focus="focusSelect"
  >
    <el-option
      v-for="item in options"
      :key="item.ruid"
      :label="item.name"
      :value="code ? item.customercode : item.ruid">
    </el-option>
  </el-select>
</template>

<script>
export default {
    directives: {
      'el-select-loadmore': {
        bind(el, binding) {
          // 获取element-ui定义好的scroll盒子
          const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
          SELECTWRAP_DOM.addEventListener('scroll', function () {
            const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
            if (condition) {
              binding.value();
            }
          });
        }
      }
    },
    props: {
      code: {
        type: Boolean,
        default: true
      },
      first: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: true
      },
      selected: Array,
    },
    watch: {
      selected(arr) {
        this.value = arr
      }
    },
    data() {
      return {
        value: '',
        options: [],
        pageSize: 50,
        searchWord: '',
        // firstLoad: true,
        loading: false,
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      loadmore() {
        this.pageSize += 10;
        this.getList();
      },
      getList() {
        this.loading = true
        this.getSchools(this.pageSize).then(res => {
          this.options = res.returnvalue.recordlist;
          if (this.first) {
            this.value = this.options[0].customercode;
            this.$emit('change-select', this.value);
          }
        });
        this.loading = false
      },
      getSchools(pageSize) {
        return this.$http({
          url: '/api/v1/platform/customer/objectlist',
          method: 'POST',
          data: {
            "pageno": 0,
            "pagesize": pageSize,
            "type": "customer",
            "filter": {
              "itemList": [
                {
                  "fieldName": "name",
                  "relationOperator": "like",
                  "searchWord": this.searchWord
                },
                {
                  "fieldName": "type",
                  "relationOperator": "in",
                  "searchWord": "3"
                }
              ]
            },
            "field": "",
            "order": {}
          }
        })
      },
      changeSelect(items) {
        const objs = items.map(item => {
          const target = this.options.find(option => option.customercode === item)
          return target
        })
        this.$emit('change-select', objs)
      },
      searchItem(val) {
        this.searchWord = val;
        this.pageSize = 50;
        this.getList();
      },
      focusSelect() {
        this.searchWord = '';
        this.pageSize = 50;
        this.getList();
      }
    }
};
</script>