export default [
  {
    label: '图书推荐',
    value: {
      title: '特殊限定',
      name: '图书推荐',
      logicalop: 'or',
      relationop: 'group',
      children: [
        {
          logicalop: 'or',
          relationop: 'join',
          join: {
            type: 'dataobjectuserstar',
          },
          children: [
            {
              logicalop: 'and',
              fieldname: 'reason',
              relationop: 'exists',
              word: '',
            },
          ],
        },
      ],
    },
  },
  {
    label: '榜单收录',
    value: {
      title: '特殊限定',
      name: '榜单收录',
      logicalop: 'or',
      relationop: 'group',
      children: [
        {
          logicalop: 'or',
          relationop: 'join',
          join: {
            type: 'dataobjecttoplist',
          },
          children: [
            {
              logicalop: 'and',
              fieldname: 'ruid',
              relationop: 'exists',
              word: '',
            },
          ],
        },
      ],
    },
  },
  {
    label: '获奖图书',
    value: {
      title: '特殊限定',
      name: '获奖图书',
      logicalop: 'or',
      relationop: 'group',
      children: [
        {
          logicalop: 'or',
          fieldname: '54001',
          relationop: 'exists',
          word: '',
        },
      ],
    },
  },
  {
    label: '知名作者',
    value: {
      title: '特殊限定',
      name: '知名作者',
      logicalop: 'or',
      relationop: 'group',
      children: [
        {
          logicalop: 'or',
          fieldname: '15900091',
          relationop: 'exists',
          word: '',
        },
      ],
    },
  },
  {
    label: '重点丛书',
    value: {
      title: '特殊限定',
      name: '重点丛书',
      logicalop: 'or',
      relationop: 'group',
      children: [
        {
          logicalop: 'or',
          fieldname: '53019',
          relationop: 'exists',
          word: '',
        },
      ],
    },
  },
  {
    label: '核心馆藏',
    value: {
      title: '特殊限定',
      name: '核心馆藏',
      logicalop: 'or',
      relationop: 'group',
      children: [
        {
          logicalop: 'or',
          fieldname: '888000035',
          relationop: '>',
          valuetype: 'N',
          word: '8',
        },
      ],
    },
  },
  {
    label: '国内现货',
    value: {
      title: '特殊限定',
      name: '现货',
      logicalop: 'or',
      relationop: 'group',
      children: [
        {
          logicalop: 'or',
          fieldname: '138',
          relationop: '>',
          valuetype: 'N',
          word: '0',
        },
      ],
    },
  },
  {
    label: '特价图书',
    value: {
      title: '特殊限定',
      name: '特价图书',
      logicalop: 'or',
      relationop: 'group',
      children: [
        {
          logicalop: 'or',
          fieldname: '137',
          relationop: '>',
          valuetype: 'D',
          word: {
            item: [
              {
                itemtype: 'systime',
                value: 'now',
              },
            ],
          },
        },
      ],
    },
  },
]
